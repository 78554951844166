/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.btn.btn-elevate {
  min-width: 100px;
}

.custom-td {
  tr {
    th:not(:first-child),
    td:not(:first-child) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 170px;
      max-width: 170px;
    }
  }
}

[data-tip] {
  position: relative;

  ::before {
    position: absolute;
    content: "";
    // hide the tooltip if not hover
    display: none;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #e9ecef;
    bottom: 30px;
    left: 35px;
    z-index: 8;
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
  }

  ::after {
    position: absolute;
    content: attr(data-tip);
    // hide the tooltip if not hover
    display: none;
    bottom: 35px;
    left: 0;
    padding: 5px 8px;
    background-color: #e9ecef;
    color: #fff;
    z-index: 9;
    font-size: 0.75rem;
    height: 18px;
    line-height: 18px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    white-space: nowrap;
    word-wrap: normal;
  }

  :hover {
    ::before,
    ::after {
      display: block;
    }
  }
}
